<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "manage-users",
              "edit-user",
              "assign-resources-to-user"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper level-search" v-if="search">
        <div class="search-field">
          <form @submit.prevent="submitSearch" novalidate>
            <input
              type="text"
              ref="searchForm"
              @keyup="searchQuery = $event.target.value"
              :placeholder="
                displayLabelName('manage-users', 'assign-resources', 'search')
              "
            />
            <div class="icon search">
              <icon icon="#cx-app1-search-16x16" width="16" height="16" />
            </div>
            <button class="clear-field" @click="clearSearch" type="button">
              <div class="svg-icon">
                <svg>
                  <use xlink:href="#cx-app1-cross-round"></use>
                </svg>
              </div>
            </button>
          </form>
        </div>
      </section>
    </template>

    <!-- Favorites start -->
    <ul
      v-if="
        favoritesActive &&
          favoriteLevels &&
          favoriteLevels.data &&
          favoriteLevels.data.length
      "
      class="clebex-item-section pill"
    >
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{
              displayLabelName(
                "manage-users",
                "assign-resources",
                "favorite-levels"
              )
            }}
          </div>
          <span class="follow-up-icons" @click="openLevels"
            ><button class="follow-up-icon-item">
              <div class="svg-icon">
                <svg><use xlink:href="#cx-app1-favourite-fill"></use></svg>
              </div></button
          ></span>
        </div>
      </li>
      <li
        class="clebex-item-section-item"
        v-for="favoriteLevel in favoriteLevels.data"
        :key="favoriteLevel.id"
      >
        <router-link
          class="clebex-pill-link"
          :to="{
            name: 'r_edit-user-add-resources-to-user-resources',
            params: { levelId: favoriteLevel.id }
          }"
        >
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                :class="{
                  default: favoriteLevel.is_default,
                  favorite:
                    favoriteLevel.is_favorite && !favoriteLevel.is_default
                }"
              >
                <icon
                  v-if="favoriteLevel.is_default"
                  icon="#cx-app1-favourite-default"
                />
                <icon
                  v-else-if="favoriteLevel.is_favorite"
                  icon="#cx-app1-favourite-fill"
                />
                <icon v-else icon="#cx-app1-favourite" />
              </button>
            </span>
            <span class="label"
              ><span class="text">
                <span class="highlight">{{
                  favoriteLevel.all_parents && favoriteLevel.all_parents.length
                    ? `${favoriteLevel.name},`
                    : favoriteLevel.name
                }}</span>
                {{ displayParentNames(favoriteLevel.all_parents) }}
              </span>
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </button>
        </router-link>
      </li>
    </ul>
    <!-- Favorites end -->

    <!-- All start -->
    <ul
      v-if="!favoritesActive && levels && levels.data && levels.data.length"
      class="clebex-item-section pill"
    >
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{
              displayLabelName(
                "manage-users",
                "assign-resources",
                "all-locations-and-favorites"
              )
            }}
          </div>
          <span class="follow-up-icons" @click="openFavorites"
            ><button class="follow-up-icon-item">
              <div class="svg-icon">
                <svg><use xlink:href="#cx-app1-favourite"></use></svg>
              </div></button
          ></span>
        </div>
      </li>
      <li
        class="clebex-item-section-item"
        v-for="level in levels.data"
        :key="level.id"
      >
        <router-link
          class="clebex-pill-link"
          :to="{
            name: 'r_edit-user-add-resources-to-user-resources',
            params: { levelId: level.id }
          }"
        >
          <button class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                :class="{
                  default: level.is_default,
                  favorite: level.is_favorite && !level.is_default
                }"
              >
                <icon
                  v-if="level.is_default"
                  icon="#cx-app1-favourite-default"
                />
                <icon
                  v-else-if="level.is_favorite"
                  icon="#cx-app1-favourite-fill"
                />
                <icon v-else icon="#cx-app1-favourite" />
              </button>
            </span>
            <span class="label"
              ><span class="text">
                <span class="highlight">{{
                  level.all_parents && level.all_parents.length
                    ? `${level.name},`
                    : level.name
                }}</span>
                {{ displayParentNames(level.all_parents) }}
              </span>
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </button>
        </router-link>
      </li>
    </ul>
    <!-- All end -->
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import PlanMixin from "@/services/mixins/plan/plan-mixin";

export default {
  name: "AddAssignableResourcesToUser",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      search: null,
      favoritesActive: false
    };
  },
  created() {
    this.openLevels();
  },
  computed: {
    ...mapState("user", ["selectedLevels"]),
    ...mapState("level", ["levels", "favoriteLevels"])
  },
  methods: {
    ...mapActions("level", ["getLevels"]),
    toggleSearch() {
      this.search = !this.search;
    },
    openLevels() {
      this.favoritesActive = false;
      const params = {
        includes: ["all_parents", "full_path"],
        depth: "BOTTOM",
        sort: ["FAVORITES"]
      };

      if (this.searchQuery) {
        params.query = this.searchQuery;
      }

      this.getLevels(params);
    },
    openFavorites() {
      this.favoritesActive = true;
      const params = {
        depth: "BOTTOM",
        favorites: 1,
        includes: ["all_parents", "default"]
      };

      if (this.searchQuery) {
        params.query = this.searchQuery;
      }

      this.getLevels(params);
    },
    submitSearch() {
      if (this.favoritesActive) {
        this.openFavorites();
      } else {
        this.openLevels();
      }
    },
    clearSearch() {
      this.$refs.searchForm.value = "";
      this.searchQuery = "";
      if (this.favoritesActive) {
        this.openFavorites();
      } else {
        this.openLevels();
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  mixins: [PlanMixin]
};
</script>
